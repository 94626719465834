<template>
  <div>
    <b-modal
      id="modal-payment-category"
      size="xl"
      title="Pilih Layanan"
      hide-footer
      @hidden="modalOnHide"
    >
      <div class="row">
        <div class="col-md-12">
          <b-button class="ml-2" variant="success" @click="saveItemChoosen">
            <i class="fas fa-plus"></i> Tambahkan Item Terpilih
          </b-button>
        </div>
        <div class="col-md-12">
          <!-- choose existing payment category -->
          <div class="mt-5">
            <Table
              ref="table"
              purpose="modal"
              type= "promo"
              :selectedItem="selectedItem"
              :selectedBundle="selectedBundle"
              @checkboxItemOnChange="checkboxItemOnChange"
              @btnSeeBundleDetailOnClick="btnSeeBundleDetailOnClick"
            />
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Table from "@/component/masters/payment-categories/TablePaymentPromo.vue";
import validation from "@/core/modules/ValidationModule.js";
import module from "@/core/modules/CrudModule.js";

export default {
  props: {
    selectedItem: Array,
    selectedBundle: Array,
  },

  components: {
    Table,
  },

  data() {
    return {
      // form
      form: {
        name: "",
        price: "",
        doctor_commission: "",
        hospital_income: "",
      },
      // error
      error: {
        name: "",
        price: "",
        doctor_commission: "",
        hospital_income: "",
      },
      // other
      totalPercentage: 100,
      isPercent: false,
      items: [],
    };
  },

  computed: {
    formatedPrice: {
      set(newValue) {
        newValue = parseInt(newValue.replace(/\D/g, ""), 10);
        if (!Number.isNaN(newValue)) {
          this.form.price = newValue.toLocaleString("id-ID");
        } else {
          this.form.price = "";
        }
      },
      get() {
        return this.form.price;
      },
    },

    formatedDoctorCommission: {
      set(newValue) {
        newValue = parseInt(newValue.replace(/\D/g, ""), 10);
        if (!Number.isNaN(newValue)) {
          this.form.doctor_commission = newValue.toLocaleString("id-ID");
        } else {
          this.form.doctor_commission = "";
        }
      },
      get() {
        return this.form.doctor_commission;
      },
    },

    formatedHospitalIncome: {
      set(newValue) {
        newValue = parseInt(newValue.replace(/\D/g, ""), 10);
        if (!Number.isNaN(newValue)) {
          this.form.hospital_income = newValue.toLocaleString("id-ID");
        } else {
          this.form.hospital_income = "";
        }
      },
      get() {
        return this.form.hospital_income;
      },
    },
  },

  methods: {
    modalOnHide() {
      this.totalPercentage = 100;
      this.form = validation.clearForm(this.form);
    },

    btnIsPercentToggle() {
      this.error.doctor_commission = "";
      this.error.hospital_income = "";
      this.totalPercentage = 100;
      this.form.doctor_commission = "";
      this.form.hospital_income = "";
      this.isPercent ? (this.isPercent = false) : (this.isPercent = true);
    },

    priceOnKeyup(evt) {
      if (
        (evt.keyCode >= 48 && evt.keyCode <= 57) ||
        (evt.keyCode >= 96 && evt.keyCode <= 105) ||
        evt.keyCode == 8
      ) {
        if (this.formatedPrice != 0) {
          if (this.isPercent) {
            this.totalPercentage = 100;
            this.form.doctor_commission = 50;
            this.form.hospital_income = 50;
          } else {
            let share = parseInt(this.form.price.replace(/\D/g, ""), 10) / 2;
            this.form.hospital_income = !isNaN(share)
              ? share.toLocaleString("id-ID")
              : "";
            this.form.doctor_commission = !isNaN(share)
              ? share.toLocaleString("id-ID")
              : "";
          }
        }
      }
    },

    doctorCommisionOnKeyUp() {
      if (this.formatedPrice != 0) {
        if (this.isPercent) {
          this.totalPercentage = 100;
          this.totalPercentage -= this.form.doctor_commission;
          this.form.hospital_income = this.totalPercentage;
        } else {
          let share = parseInt(this.form.price.replace(/\D/g, ""), 10);
          share -= parseInt(this.form.doctor_commission.replace(/\D/g, ""), 10);
          this.form.hospital_income = !isNaN(share)
            ? share.toLocaleString("id-ID")
            : "";
        }
      }
    },

    hospitalIncomeOnKeyUp() {
      if (this.formatedPrice != 0) {
        if (this.isPercent) {
          this.totalPercentage = 100;
          this.totalPercentage -= this.form.hospital_income;
          this.form.doctor_commission = this.totalPercentage;
        } else {
          let share = parseInt(this.form.price.replace(/\D/g, ""), 10);
          share -= parseInt(this.form.hospital_income.replace(/\D/g, ""), 10);
          this.form.doctor_commission = !isNaN(share)
            ? share.toLocaleString("id-ID")
            : "";
        }
      }
    },

    checkboxItemOnChange(value, index, e) {
      if (e) {
        this.items.push(value);
      } else {
        let found = this.items.findIndex((x) => x.id == value.id);
        index = this.items.indexOf(this.items[found]);
        this.items.splice(index, 1);
      }
    },

    async saveItemChoosen() {
      // save data
      let data = {
        items: this.items,
      };

      let response = await module.submit(
        data,
        "/promos/add-items/" + this.$route.params.id
      );
      // Check Response
      if (response.state == "success") {
        // Success
        Swal.fire(response.success.title, response.success.message, "success");
        this.$bvModal.hide('modal-payment-category')
        this.$emit("updateServiceItem");
      }
    },

    btnSeeBundleDetailOnClick(value, index) {
      this.$emit("btnSeeBundleDetailOnClick", value, index);
    },

    customCategoryFormOnSubmit() {
      let intPrice = parseInt(this.form.price.replace(/\D/g, ""), 10);
      if (!this.isPercent) {
        let intDoctorCommission = parseInt(
          this.form.doctor_commission.replace(/\D/g, ""),
          10
        );
        let intHospitalIncome = parseInt(
          this.form.hospital_income.replace(/\D/g, ""),
          10
        );
        if (intPrice < intDoctorCommission || intPrice < intHospitalIncome) {
          Swal.fire(
            "Gagal",
            "Komisi Dokter / Pendapatan Rumah Sakit tidak boleh melebihi Harga",
            "warning"
          );
        } else {
          this.$emit("customSubmitedCategory", {
            label: this.form.name,
            id: 0,
            price: parseInt(this.form.price.replace(/\D/g, ""), 10),
            const_price: parseInt(this.form.price.replace(/\D/g, ""), 10),
            ref_id: 0,
            discount_percentage: 0,
            discount_amount: 0,
            discount_type: 0,
            doctor_commission: intDoctorCommission,
            hospital_income: intHospitalIncome,
            quantity: 1,
            custom_input: true,
          });
          this.$bvModal.hide("modal-payment-category");
        }
      } else {
        if (
          this.form.doctor_commission > this.totalPercentage ||
          this.form.hospital_income > this.totalPercentage
        ) {
          Swal.fire(
            "Gagal",
            "Komisi Dokter / Pendapatan Rumah Sakit tidak boleh melebihi 100%",
            "warning"
          );
        } else {
          let price = parseInt(this.form.price.replace(/\D/g, ""), 10);
          let calculatedDoctorCommission =
            (price * this.form.doctor_commission) / 100;
          let calculatedHospitalIncome =
            (price * this.form.hospital_income) / 100;
          this.$emit("customSubmitedCategory", {
            label: this.form.name,
            id: 0,
            price: price,
            const_price: price,
            ref_id: 0,
            discount_percentage: 0,
            discount_amount: 0,
            discount_type: 0,
            doctor_commission: calculatedDoctorCommission,
            hospital_income: calculatedHospitalIncome,
            is_bundle: 0,
            is_deposit: 0,
            quantity: 1,
            custom_input: true,
          });
          this.$bvModal.hide("modal-payment-category");
        }
      }
    },
  },
};
</script>

<style scoped>
.bg-light-primary:hover {
  background-color: #e1f0ffa6 !important;
}
</style>