<template>
  <div>

    <!--
      Author: Andra yuda
      Usage: [ModalPaymentCategoryBundleItem]
      Emit: checkboxItemOnChange
            params =>(:Object rowData)
    -->

    <b-table
      striped
      hover
      class="mt-3"
      responsive
      :items="items"
      :fields="fields"
    >

      <!-- price -->
      <template #cell(payment_categories_price)="data">
        {{ parseInt(data.item.payment_categories_price).toLocaleString('id-ID') }}
      </template>

      <!-- checkbox -->
      <!-- <template #cell(actions)='data'>
        <b-form-checkbox
          :checked="setChecked(data.item)"
          :id="`checkbox-pick-bundle-item-${data.item.id}`"
          :name="`checkbox-pick-bundle-item-${data.item.id}`"
          @change="checkboxItemOnChange({
              id: data.item.payment_categories_id,
              name: data.item.payment_categories_name,
              name: data.item.payment_categories_name,
            })"
        >
        </b-form-checkbox>
      </template> -->

    </b-table>

  </div>
</template>

<script>

export default {

  props: {
    items: Array
  },

  data() {
    return {
      // table fields
      fields: [
        {
          key: "number",
          label: '#',
          sortable: true,
        },
        {
          key: "payment_categories_name",
          label: 'Nama',
          sortable: true,
        },
        {
          key: "payment_categories_price",
          label: 'Harga',
          sortable: true,
        },
        { key: "actions", label: "" },
      ]
    }
  },

  methods: {

    // checkbox on change
    checkboxItemOnChange(value) {
      this.$emit('checkboxItemOnChange', value)
    },

    // set checkbox checked state
    setChecked(data) {
      return data.checked == 1
    }

  }

}
</script>

<style>
</style>