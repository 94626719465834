var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row justify-content-end mt-n3"},[_c('div',{staticClass:"col-md-3"},[_c('b-input-group',{scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('b-input-group-text',{attrs:{"squared":""}},[_c('b-icon-search')],1)]},proxy:true}])},[_c('b-form-input',{attrs:{"type":"text","placeholder":"Cari Nama Dokter"},on:{"keyup":_vm.filterByName},model:{value:(_vm.filter.name),callback:function ($$v) {_vm.$set(_vm.filter, "name", $$v)},expression:"filter.name"}})],1)],1)]),_c('b-table',{staticClass:"mt-3",attrs:{"striped":"","hover":"","items":_vm.items,"fields":_vm.fields},scopedSlots:_vm._u([{key:"table-colgroup",fn:function(scope){return _vm._l((scope.fields),function(field){return _c('col',{key:field.key,style:({ width: field.key === 'actions' ? '15%' : '' })})})}},{key:"cell(created_at)",fn:function(data){return [_vm._v(" "+_vm._s(new Date(Date.parse(data.item.created_at)).toLocaleString("id-ID", { weekday: "long", month: "numeric", year: "numeric", day: "numeric", }))+" ")]}},{key:"cell(actions)",fn:function(data){return [(_vm.purpose == 'modal')?[_c('div',{staticClass:"d-flex justify-content-center align-items-center"},[(data.item.price >= _vm.promo.amount)?_c('b-form-checkbox',{attrs:{"size":"lg","checked":data.item.checked == 1,"id":`checkbox-pick-payment-category-${data.item.id}`,"name":`checkbox-pick-payment-category-${data.item.id}`},on:{"change":function($event){return _vm.checkboxItemOnChange(
                  {
                    id: data.item.id,
                    name: data.item.name,
                    price: data.item.price,
                    const_price: data.item.price,
                    is_bundle: data.item.isBundle,
                  },
                  data.index,
                  $event
                )}}}):_vm._e(),(data.item.price < _vm.promo.amount)?_c('span',{staticClass:"text-danger"},[_vm._v("Harga Lebih Besar Dari Potongan")]):_vm._e()],1)]:_vm._e()]}}])}),(_vm.items.length != 0)?_c('b-pagination',{staticClass:"mt-4",attrs:{"total-rows":_vm.totalRows,"per-page":_vm.perPage},on:{"page-click":_vm.pageOnClick},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }