<template>
  <div>
    <b-modal id="modal-labs" size="xl" title="Pilih Lab" hide-footer>
      <div class="row">
        <div class="col-md-12">
          <b-button class="ml-2" variant="success" @click="saveItemChoosen">
            <i class="fas fa-plus"></i> Tambahkan Item Terpilih
          </b-button>
        </div>
        <div class="col-md-12">
          <Table
            purpose="modal"
            :selectedItem="items"
            @checkboxItemOnChange="chosenMedicine"
            @packageMedicine="packageMedicine"
            :secondPurpose="secondPurpose"
          />
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import Table from "@/component/lab-procedures-2/TablePromo.vue";

export default {
  props: {
    selectedItem: Array,
    checkbox: Boolean,
    isHospitalTransaction: Boolean,
    isSortcut: Boolean,
    isAbjad: Boolean,
    secondPurpose: {
      type: String,
      default: "listing",
    },
  },

  data() {
    return {
      items: [],
    };
  },

  components: {
    Table,
  },

  methods: {
    chosenMedicine(value,index, e) {
      console.log(value);
      if (e) {
        this.items.push(value);
      } else {
        let found = this.items.findIndex((x) => x.id == value.id);
        index = this.items.indexOf(this.items[found]);
        this.items.splice(index, 1);
      }

      console.log(this.items);
    },

    async saveItemChoosen() {
      // save data
      let data = {
        items: this.items,
      };

      let response = await module.submit(
        data,
        "/promos/add-item-labs/" + this.$route.params.id
      );
      // Check Response
      if (response.state == "success") {
        // Success
        Swal.fire(response.success.title, response.success.message, "success");
        this.$bvModal.hide("modal-labs");
        this.$emit("updateServiceItemLab");
      }
    },

    packageMedicine(datas) {
      this.$emit("packageMedicine", datas);
    },
  },
  mounted() {
    console.log(this.isSortcut, "sortcut");
  },
};
</script>

<style>
</style>