<template>
  <div>
    <!-- Main Row -->
    <div class="row">
      <!-- Form -->
      <div class="col-md-12">
        <Card :title="'Detail Promo'">
          <template v-slot:body>
            <div class="row align-items-center">
              <!-- Profile Image -->
              <div class="col-md-4">
                <div
                  class="d-flex justify-content-center align-items-center image-container"
                >
                  <div style="height: 350px; width: 350px; position: relative">
                    <img
                      class="image"
                      src="/images/default-payment-method.svg"
                      @load="onImageLoad()"
                    />
                  </div>
                </div>
              </div>

              <!-- Table Data -->
              <div class="col" v-if="imgLoaded">
                <table class="table mb-3">
                  <tr>
                    <td style="width: 25%"><strong>Nama</strong></td>
                    <td>{{ data.name }}</td>
                  </tr>
                  <tr>
                    <td style="width: 25%">
                      <strong>Besaran Potongan</strong>
                    </td>
                    <td v-if="data.is_percent == 0">
                      {{ parseInt(data.amount).toLocaleString("id-ID") }}
                    </td>
                    <td v-if="data.is_percent == 1">{{ data.amount }}%</td>
                  </tr>
                  <tr>
                    <td style="width: 25%"><strong>Durasi</strong></td>
                    <td>{{ data.start_date + " s/d " + data.end_date }}</td>
                  </tr>
                  <tr>
                    <td><strong>Deskripsi</strong></td>
                    <td>{{ data.description }}</td>
                  </tr>
                </table>

                <button
                  @click="
                    $router.push({ path: '/masters/promos/edit/' + data.id })
                  "
                  class="btn mx-1 btn-success"
                  v-if="btn"
                >
                  <i class="far fa-fw fa-edit"></i> Edit
                </button>
                <!-- <button
                  @click="deleteDataServices"
                  class="btn mx-1 btn-danger"
                  v-if="btn"
                >
                  <i class="far fa-fw fa-trash-alt"></i> Hapus
                </button> -->
              </div>
            </div>
          </template>
        </Card>
      </div>

      <div class="col-md-12">
        <Card :title="'Tambah Item Promo'">
          <template v-slot:body>
            <div class="row align-items-center">
              <div class="col-md-12 d-flex justify-content-between">
                <span class="font-weight-bold h6">Item Layanan</span>
                <b-button
                  class="ml-2"
                  variant="primary"
                  @click="$bvModal.show('modal-payment-category')"
                >
                  <i class="fas fa-plus"></i> Item Layanan
                </b-button>
              </div>
              <div class="col-md-12">
                <b-table
                  striped
                  hover
                  class="mt-3"
                  responsive
                  :items="items"
                  :fields="fields"
                >
                  <template #table-colgroup="scope">
                    <col
                      v-for="field in scope.fields"
                      :key="field.key"
                      :style="{ width: field.key === 'actions' ? '20%' : '' }"
                    />
                  </template>
                  <template #cell(display_price)="data">
                    {{ parseInt(data.item.price).toLocaleString("id-ID") }}
                  </template>
                  <template #cell(actions)="data">
                    <b-button
                      size="sm"
                      class="btn-danger"
                      v-b-tooltip.hover
                      title="Hapus"
                      @click="deleteDataServices(data.item.id)"
                      ><i class="fas fa-trash px-0"></i
                    ></b-button>
                  </template>
                </b-table>
              </div>
              <div class="col-md-12 d-flex justify-content-between">
                <span class="font-weight-bold h6">Item Obat & Alkes</span>
                <b-button
                  class="ml-2"
                  variant="primary"
                  @click="$bvModal.show('modal-medicine')"
                >
                  <i class="fas fa-plus"></i> Item Obat & Alkes
                </b-button>
              </div>
              <div class="col-md-12">
                <b-table
                  striped
                  hover
                  class="mt-3"
                  responsive
                  :items="itemMedicines"
                  :fields="fieldMedicines"
                >
                  <template #table-colgroup="scope">
                    <col
                      v-for="field in scope.fields"
                      :key="field.key"
                      :style="{ width: field.key === 'actions' ? '20%' : '' }"
                    />
                  </template>
                  <template #cell(display_price)="data">
                    {{
                      parseInt(data.item.display_price).toLocaleString("id-ID")
                    }}
                  </template>
                  <template #cell(actions)="data">
                    <b-button
                      size="sm"
                      class="btn-danger"
                      v-b-tooltip.hover
                      title="Hapus"
                      @click="deleteDataMedicine(data.item.id)"
                      ><i class="fas fa-trash px-0"></i
                    ></b-button>
                  </template>
                </b-table>
              </div>
              <div class="col-md-12 d-flex justify-content-between">
                <span class="font-weight-bold h6">Item Lab</span>
                <b-button
                  class="ml-2"
                  variant="primary"
                  @click="$bvModal.show('modal-labs')"
                >
                  <i class="fas fa-plus"></i> Pemeriksaan Lab
                </b-button>
              </div>
              <div class="col-md-12">
                <b-table
                  striped
                  hover
                  class="mt-3"
                  responsive
                  :items="itemLabs"
                  :fields="fieldLabs"
                >
                  <template #table-colgroup="scope">
                    <col
                      v-for="field in scope.fields"
                      :key="field.key"
                      :style="{ width: field.key === 'actions' ? '20%' : '' }"
                    />
                  </template>
                  <template #cell(display_price)="data">
                    {{ parseInt(data.item.price).toLocaleString("id-ID") }}
                  </template>
                  <template #cell(actions)="data">
                    <b-button
                      size="sm"
                      class="btn-danger"
                      v-b-tooltip.hover
                      title="Hapus"
                      @click="deleteDataLabs(data.item.id)"
                      ><i class="fas fa-trash px-0"></i
                    ></b-button>
                  </template>
                </b-table>
              </div>
            </div>
          </template>
        </Card>
      </div>
    </div>

    <!-- service modal -->
    <ModalService
      :selectedItem="items"
      @btnItemBundleOnClick="setServiceBundleItem"
      @btnSeeBundleDetailOnClick="seeServiceBundleItem"
      @checkboxItemOnChange="modalServiceOnSelect"
      @customSubmitedCategory="modalServiceOnSelect"
      @updateServiceItem="updateServiceItem"
    />
    <ModalServiceBundleItem ref="modalServiceBundleItem" />

    <!-- medicine Modal -->
    <ModalMedicine
      :selectedItem="itemMedicines"
      :isHospitalTransaction="true"
      :checkbox="true"
      :isAbjad="true"
      @updateServiceItemMedicine="updateServiceItemMedicine"
    />

    <!-- medicine Modal -->
    <ModalLab
      :selectedItem="itemLabs"
      :isHospitalTransaction="true"
      :checkbox="true"
      :isAbjad="true"
      @updateServiceItemLab="updateServiceItemLab"
    />
  </div>
</template>

<script>
import Card from "@/view/content/Card.vue";
import module from "@/core/modules/CrudModule.js";
import ModalService from "@/component/general/ModalPaymentCategoryPromo.vue";
import ModalMedicine from "@/component/general/ModalMedicinePromo.vue";
import ModalLab from "@/component/general/ModalLabPromo.vue";
import ModalServiceBundleItem from "@/component/general/ModalPaymentCategoryBundleItem.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  data() {
    return {
      // Data
      data: {
        name: "",
        description: "",
      },
      // Other
      imgLoaded: false,
      // user access
      btn: false,
      // form Items
      form: {},
      // table Item
      items: [],
      itemLabs: [],
      itemMedicines: [],
      fields: [
        {
          key: "number",
          label: "#",
          sortable: true,
        },
        {
          key: "name",
          label: "Nama",
          sortable: true,
        },
        {
          key: "display_price",
          label: "Harga",
          sortable: true,
        },
        {
          key: "actions",
          label: "",
        },
      ],
      fieldMedicines: [
        {
          key: "number",
          label: "#",
          sortable: true,
        },
        {
          key: "name",
          label: "Nama",
          sortable: true,
        },
        {
          key: "display_price",
          label: "Harga",
          sortable: true,
        },
        {
          key: "actions",
          label: "",
        },
      ],
      fieldLabs: [
        {
          key: "number",
          label: "#",
          sortable: true,
        },
        {
          key: "name",
          label: "Nama",
          sortable: true,
        },
        {
          key: "display_price",
          label: "Harga",
          sortable: true,
        },
        {
          key: "actions",
          label: "",
        },
      ],
    };
  },

  components: {
    Card,
    ModalService,
    ModalServiceBundleItem,
    ModalMedicine,
    ModalLab,
  },

  methods: {
    onImageLoad() {
      this.imgLoaded = true;
    },

    async deleteData() {
      let result = await module.delete("promos/" + this.$route.params.id);
      // If Deleted
      if (result) {
        // Redirect To List
        this.$router.push("/masters/promos/list");
      }
    },

    async get() {
      this.data = await module.get("promos/" + this.$route.params.id);
      // If Data Not Found
      if (this.data == null) {
        // Redirect To List
        this.$router.push("/masters/promos/list");
      }
    },

    async updateServiceItem() {
      this.items = await module.get(
        "payment-categories/promo-items/" + this.$route.params.id
      );
      // If Data Not Found
      if (this.items == null) {
        // Redirect To List
        this.$router.push("/masters/promos/list");
      }
    },

    setActiveButton() {
      let access_right_user = window.localStorage.getItem(
        "access_right_display"
      );
      let access_right = JSON.parse(access_right_user);

      let a;
      for (a = 0; a < access_right.length; a++) {
        if (access_right[a] == "1802") {
          this.btn = true;
        }
      }
    },

    // Item Promo
    // services

    setServiceBundleItem(value, index, isChecked) {
      console.log("On SET", value, index, isChecked);
    },
    modalServiceOnSelect(value, index, isChecked) {
      if (isChecked) {
        let number = this.items.length + 1;
        value.number = number;
        value.display_price = parseInt(value.price).toLocaleString("id-ID");
        this.items.push(value);
      } else {
        let found = this.items.findIndex((x) => x.id == value.id);
        // this.items.splice(index, 1);
        index = this.items.indexOf(this.items[found]);
        this.items.splice(index, 1);

        this.items.forEach((el, i) => {
          el.number = i + 1;
        });
      }
    },
    // modal service see bundle item
    seeServiceBundleItem(value, index) {
      this.$bvModal.show("modal-payment-category-bundle-items");
      this.$refs.modalServiceBundleItem.getData(value);
    },

    async deleteDataServices(id) {
      // Delete Data
      let result = await module.delete(
        "payment-categories/unset-promo-items/" + id
      );
      // If Deleted
      if (result) {
        this.updateServiceItem();
      }
    },

    // medicines
    // modal medicine @select
    async updateServiceItemMedicine() {
      this.itemMedicines = await module.get(
        "medicines/promo-items/" + this.$route.params.id
      );
      // If Data Not Found
      if (this.itemMedicines == null) {
        // Redirect To List
        this.$router.push("/masters/promos/list");
      }
    },

    async deleteDataMedicine(id) {
      // Delete Data
      let result = await module.delete("medicines/unset-promo-items/" + id);
      // If Deleted
      if (result) {
        this.updateServiceItemMedicine();
      }
    },

    // labs
    async deleteDataLabs(id) {
      // Delete Data
      let result = await module.delete(
        "2-lab-procedures/unset-promo-items/" + id
      );
      // If Deleted
      if (result) {
        this.updateServiceItemLab();
      }
    },

    async updateServiceItemLab() {
      this.itemLabs = await module.get(
        "2-lab-procedures/promo-items/" + this.$route.params.id
      );
      // If Data Not Found
      if (this.itemLabs == null) {
        // Redirect To List
        this.$router.push("/masters/promos/list");
      }
    },
  },

  mounted() {
    // BreadCrumb
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Promo", route: "" },
      { title: "Daftar Promo", route: "/masters/promos/list" },
      { title: "Detail" },
    ]);
    // Get Data
    this.get();
    this.setActiveButton();
    this.updateServiceItem();
    this.updateServiceItemMedicine();
    this.updateServiceItemLab();
  },
};
</script>