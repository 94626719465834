<template>
  <div>

    <!--
      Author: Andra yuda
      Usage: [payment/add, payment/edit]
      Emit: modalOnSubmit
            params => (:Array bundleItems)
    -->

    <b-modal
      id="modal-payment-category-bundle-items"
      size="xl"
      title="Isi Paket Bundle"
      cancel-title="Batal"
      hide-footer
      ok-title="Oke"
      @ok="modalOnSubmit"
    >

      <Table
        :items="items"
        @checkboxItemOnChange="checkboxItemOnChange"
      />

    </b-modal>

  </div>
</template>

<script>

import module from '@/core/modules/CrudModule.js'
import Table from '@/component/payment-category-bundle-items/Table.vue'

export default {

  components: {
    Table
  },

  props: {
    selectedItem: Array
  },

  data() {
    return {
      items: [],
      paymentCategory: {}
    }
  },

  methods: {

    // get table data
    async getData(paymentCategory, paymentDetailId = null) {
      this.paymentCategory = paymentCategory
      if (!paymentDetailId) {
        let response = await module.get(`payment-categories-bundle/${paymentCategory.id}`)
        this.items = response
        this.selectedItem.forEach((value, index) => {
          if (value.payment_categories_id == this.items[index].payment_categories_id && value.parent_id == this.paymentCategory.id && value.checked == 1) {
            this.items[index].checked = 1
          }
        })
      } else {
        let response = await module.get(`payment-detail-bundle-items/get-by-payment-detail/${paymentDetailId}`)
        this.items = response
        this.selectedItem.forEach((value, index) => {
          if (value.payment_categories_id == this.items[index].payment_categories_id && value.parent_id == this.paymentCategory.id && value.checked == 1) {
            this.items[index].checked = 1
          }
        })
      }
    },

    // modal on submit
    modalOnSubmit() {
      this.$emit('modalOnSubmit', this.items, this.paymentCategory)
    },

    // checkbox on change
    checkboxItemOnChange(value) {
      let index = this.items.findIndex(x => x.payment_categories_id == value.id)
      if (index >= 0) {
        let item = this.items[index]
        typeof item.checked === 'undefined'
          ? item.checked = 1
          : item.checked == 1 ? item.checked = 0 : item.checked = 1
        this.items[index] = item
      }
    }

  }

}
</script>

<style>
</style>